<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <!-- elko config -->
      <config-area title="elko config" category="elko" />
      <!-- elko mapping -->
      <b-card title="Kategoriju savienojumi" class="col-12">
        <div class="row">
          <div class="col-md-4">
            <spinner v-if="!office_cat" />
            <b-form-select v-else v-model="sel_off_cat" :options="office_cat">
              <template #first>
                <b-form-select-option :value="null">-- elko --</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-md-4"><woo-cat-choose v-model="sel_woo_cat" /></div>
          <div class="col-md-2"><b-form-input type="number" v-model="uzcenojums" placeholder="Uzcenojums %"></b-form-input></div>
          <div class="col-md-2">
            <b-button @click="add_mapping()" v-show="sel_off_cat && sel_woo_cat && uzcenojums"
              >Pievienot</b-button
            >
          </div>
        </div>
        <div class="row mapping">
          <div class="col-md-12" :key="idx" v-for="(obj, idx) in mapping">
            <a @click="rem_mapping(idx)"><v-icon name="minus-square"></v-icon></a>&nbsp;
            <b-badge variant="primary">{{obj.partner_name}}</b-badge> ->
            <b-badge variant="secondary">{{obj.woo_name}}</b-badge> -> {{obj.uzcenojums}}%
          </div>
        </div>
      </b-card>
      <b-card class="blacklist" title="elko blacklist">
        <div class="row">
        <div class="col-md-10"><b-form-input v-model="black_val" placeholder="Ieraksti produkta elko kodu"></b-form-input></div>
        <div class="col-md-2"><b-button @click="add_blacklist">Pievienot</b-button></div>
        </div>
        <div class="row">
          <div class="col-md-12" style="padding-top: 15px;">
            <b-badge :key="idx" v-for="(bl,idx) in blacklist" variant="secondary">{{bl}} <a @click="rem_blacklist(idx)"><v-icon name="minus-square"></v-icon></a></b-badge>
          </div>
        </div>
      </b-card>
      <b-card class="blacklist" title="Neielasīt kā produkta atribūtus">
        <div class="row">
          <div class="col-md-10"><b-form-input v-model="attr_val" placeholder="Ieraksti nosaukumu"></b-form-input></div>
          <div class="col-md-2"><b-button @click="add_attrib">Pievienot</b-button></div>
        </div>
        <div class="row">
          <div class="col-md-12" style="padding-top: 15px;">
            <b-badge :key="idx" v-for="(bl,idx) in attrib" variant="secondary">{{bl}} <a @click="rem_attrib(idx)"><v-icon name="minus-square"></v-icon></a></b-badge>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ConfigArea from "@/components/ConfigArea.vue";
import WooCatChoose from "@/components/WooCategoryChoose.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  data() {
    return {
      office_cat: null,
      sel_off_cat: null,
      sel_woo_cat: null,
      mapping: [],
      uzcenojums: null,
      black_val: null,
      attr_val: null,
      blacklist: [],
      attrib: []
    };
  },
  components: { ConfigArea, WooCatChoose, Spinner },
  methods: {
    add_mapping() {
      this.mapping.push({
        partner_id: this.sel_off_cat,
        partner_name: this.office_cat.find(option => option.value === this.sel_off_cat).text,
        woo_cat: this.sel_woo_cat.value,
        woo_name: this.sel_woo_cat.text,
        uzcenojums: this.uzcenojums,
      });
      this.sel_off_cat = null;
      this.sel_woo_cat = null;
      this.backend.put("/partners/mapping/elko", this.mapping);
    },
    rem_mapping(idx) {
      this.mapping.splice(idx,1)
      this.backend.put("/partners/mapping/elko", this.mapping);
    },
    add_blacklist()
    {
      this.blacklist.push(this.black_val)
      this.backend.post("/partners/blacklist/elko", {'value': this.black_val})
      this.black_val=null
    },
    rem_blacklist(idx)
    {
      const val=this.blacklist[idx]
      this.blacklist.splice(idx,1)
      this.backend.delete("/partners/blacklist/elko/"+val);
    },
    add_attrib()
    {
      this.attrib.push(this.attr_val)
      this.backend.post("/partners/attrib/elko", {'value': this.attr_val})
      this.attr_val=null
    },
    rem_attrib(idx)
    {
      const val=this.attrib[idx]
      this.attrib.splice(idx,1)
      this.backend.delete("/partners/attrib/elko/"+val);
    }
  },
  created() {
    this.backend.get("/partners/elko").then((resp) => {
      if (resp.data) {
        this.office_cat = resp.data.options;
        this.mapping = resp.data.mapping
        this.blacklist = resp.data.blacklist
        this.attrib = resp.data.attrib
      }
    });
  },
};
</script>

<style scoped>
.mapping .badge, .blacklist .badge  {font-size: 1.1em;}
.blacklist .badge {margin: 5px;}
.mapping div {margin-top: 10px;}
.icon{
    color: red;
    cursor: pointer;
}
.icon:hover{
    color:rgb(141, 1, 1)
}
</style>